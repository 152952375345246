<template>
  <div class="view-box">
    <div class="login_All">
      <div class="login_container">
        <div class="login_body">
          <div class="login-form">
            <div class="login-logo">
              <div class="font">{{appName}}</div>
            </div>
            <div class="login-video">
              <video id="video" muted="muted" style="width: 100%;height: 100%; border-radius: 10px; outline: none;" src="../../assets/video/loginVideo.mp4" autoplay="autoplay" loop></video>
            </div>
            <div :loading="loading1||loading2||loading3" size="medium" type="primary" class="login-loadingClass">
              <span v-if="loading1">正在验证登录信息...</span>
              <span v-if="loading2">正在进入系统主页...</span>
              <span v-if="loading3">正在跳转认证中心...</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'login',
  data () {
    return {
      appName: "智 慧 云 平 台",
      loading1: false,
      loading2: false,
      loading3: false
    }
  },
  mounted () {
    this.isLogin();
  },
  methods: {
    isLogin () {
      this.loading1 = true;
      let back = this.getParam('back', '/');
      let ticket = this.getParam('ticket');

      if (ticket) {
        this.doLoginByTicket(ticket, back);
      } else {
        this.$api.isLogin().then(result => {

          setTimeout(() => {
            this.loading1 = false;
            //已经登录
            if (result.isLogin) {
              this.loading2 = true;
              this.$router.push({ path: '/main' });
              setTimeout(() => {
                this.loading2 = false;
                window.sessionStorage.setItem('Tit-token', result.tokenValue);
              }, 2000);
            } else {
              this.loading3 = true;
              setTimeout(() => {
                this.loading3 = false;

                this.goSsoAuthUrl();

              }, 2000);
            }
          }, 2000);

        })
      }

    },
    // 重定向至认证中心
    goSsoAuthUrl () {
      let data = {
        clientLoginUrl: location.href
      }
      this.$api.goSsoAuthUrl(data).then(res => {
        console.log(res);
        location.href = res;
      })
    },
    // 根据ticket值登录
    doLoginByTicket (ticket, back) {
      this.$api.doLoginByTicket({ ticket: ticket }).then(res => {
        localStorage.setItem('Tit-token', res);
        location.href = decodeURIComponent(back);
      })
    },
    getParam (name, defaultValue) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == name) { return pair[1]; }
      }
      return (defaultValue == undefined ? null : defaultValue);
    }
  }
}
</script>

<style scoped>
.view-box {
  width: 100%;
  height: 100%;
}
.login_All {
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: #3a73b1;
  overflow: hidden;
}
.login_container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 650px;
  margin: 0 auto;
  z-index: 1;
  transform: translate(-50%, -50%);
  box-shadow: -15px 20px 50px #09509e;
  background-image: linear-gradient(to bottom right, #09509e, #9dc8e5);
  border-radius: 10px;
}
.login_body {
  width: inherit;
  display: flex;
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.login-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.login-logo .font {
  color: #ffffff;
  font-weight: bold;
  font-size: 2rem;
}
.login-form {
  width: 100%;
  padding: 60px;
  border-radius: 10px;
}
.login-footer {
  width: 100%;
  color: #efefef;
  font-size: 12px;
  position: absolute;
  bottom: 3%;
  text-align: center;
}
.login-loadingClass {
  color: #efefef;
  font-size: 0.8rem;
  text-align: center;
}

@media (max-width: 650px) OR (max-height: 500px) {
  .login_container {
    width: 400px;
  }
  .login-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .login-logo .font {
    font-size: 1.6rem;
  }
  .login-form {
    padding: 40px;
  }
}
</style>
